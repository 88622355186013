import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import ApplicationAdapter from 'qonto/adapters/application';
import { cardAcquirerNamespace } from 'qonto/constants/hosts';

export default class CardAcquirerPaymentAdapter extends ApplicationAdapter {
  namespace = cardAcquirerNamespace;

  @waitFor
  async search(query, headers = {}) {
    let response = await adapterAction(this, 'payments', {
      method: 'POST',
      path: 'search',
      data: query,
      headers,
    });

    this.store.pushPayload({
      'card-acquirer-payment': response.payments,
    });

    let payments = response.payments.map(payment =>
      this.store.peekRecord('card-acquirer-payment', payment.id)
    );

    return { payments, meta: response.meta };
  }
}
