import AdapterError from '@ember-data/adapter/error';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import ApiAdapter from 'qonto/adapters/base/api';
import { transactionNamespace } from 'qonto/constants/hosts';
import { DEFAULT_SEARCH_INCLUDES } from 'qonto/constants/transactions';

export class VatDetectionError extends AdapterError {
  constructor() {
    super();
    this.name = 'VatDetectionError';
  }
}

export default class TransactionAdapter extends ApiAdapter {
  @service store;

  namespace = transactionNamespace;

  handleResponse(status, headers, payload, requestData) {
    // If the vat has already been set (whether it be manually or auto-detected), calling the /detect-vat endpoint will fail with a 405 error, it only happens when we have stale transaction record
    if (requestData.url.endsWith('/detect_vat') && status === 405) {
      return new VatDetectionError();
    }

    return super.handleResponse(...arguments);
  }

  @waitFor
  async search(query) {
    let response = await adapterAction(this, 'transactions', {
      method: 'POST',
      path: 'search',
      data: query,
    });

    response.transactions.forEach(transaction => {
      let transactionRecord = this.store.peekRecord('transaction', transaction.id);
      if (transactionRecord) {
        transactionRecord.set('categoryAssignment', transaction.category_assignment);
      }
    });

    [...DEFAULT_SEARCH_INCLUDES, 'transactions'].forEach(resource => {
      if (response[resource]?.length) {
        this.store.pushPayload({ [resource]: response[resource] });
      }
    });

    let transactions = response.transactions.map(transaction =>
      this.store.peekRecord('transaction', transaction.id)
    );

    return { transactions, meta: response.meta };
  }

  @waitFor
  async getBalance(query) {
    return await adapterAction(this, 'transaction', {
      method: 'POST',
      path: 'statistics/balance',
      data: query,
    });
  }

  @waitFor
  async getCashflow(query) {
    return await adapterAction(this, 'transaction', {
      method: 'POST',
      path: 'statistics/cashflow',
      data: query,
    });
  }

  @waitFor
  async insights(query = {}) {
    let insightsResponse = await adapterAction(this, 'transaction', {
      method: 'POST',
      path: 'insights',
      data: query,
    });

    let insights = insightsResponse.insights.reduce((result, insight) => {
      result[insight.label] = insight.data.value;
      return result;
    }, {});

    return insights;
  }
}
