import { service } from '@ember/service';

import dayjs from 'dayjs';

import ApplicationAbility from './application';

export default class FinancingAbility extends ApplicationAbility {
  @service organizationManager;

  get canAccess() {
    return Boolean(this.pricePlanFeatures.financingHub) && this.permissions.financing_hub.access;
  }

  get canAccessPartners() {
    return dayjs(this.organizationManager.organization.legalRegistrationDate).isSameOrBefore(
      dayjs().subtract(6, 'months')
    );
  }

  get canRequest() {
    let isUboOrCorporateOfficer = this.membership.ubo || this.membership.corporateOfficer;

    return Boolean(this.isKYBAccepted && this.membership.kycAccepted && isUboOrCorporateOfficer);
  }

  get canAccessPayLater() {
    return (
      this.membership.corporateOfficer &&
      this.membership.kycAccepted &&
      this.permissions.pay_later_eligibility.read &&
      this.organization.hasPayLaterFeature &&
      Boolean(this.pricePlanFeatures.payLater)
    );
  }

  get canViewPayLaterToggle() {
    return this.canAccessPayLater && this.permissions.pay_later_external_transfers.create;
  }

  get canCreatePayLaterTransfer() {
    return this.canViewPayLaterToggle && !this.organization.hasPreventPayLaterToggle;
  }

  get canNavigate() {
    return this.canAccess;
  }
}
