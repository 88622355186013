import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import ApplicationAdapter from 'qonto/adapters/application';
import { cardAcquirerNamespace } from 'qonto/constants/hosts';

export default class CardAcquirerOnboardingAdapter extends ApplicationAdapter {
  namespace = cardAcquirerNamespace;

  @waitFor
  async fetchTapToPayOnboarded({ organizationId }) {
    let response = await adapterAction(this, 'onboarding/organization', {
      method: 'GET',
      path: 'me',
      headers: {
        'X-Qonto-Organization-ID': organizationId,
      },
    });

    return response.is_ttp_onboarded;
  }
}
