import { tableViewsNamespace } from 'qonto/constants/hosts';

import ApplicationAdapter from './application';

export default class TableViewAdapter extends ApplicationAdapter {
  namespace = tableViewsNamespace;

  pathForType() {
    return 'tables/views';
  }
}
