// @ts-expect-error -- missing declarations
import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { receivableInvoiceV5Namespace } from 'qonto/constants/hosts';

export default class ReceivableInvoicesUploadAdapter extends JSONAPIAdapter {
  namespace = receivableInvoiceV5Namespace;

  pathForType() {
    return 'receivable_invoices/uploads';
  }

  get urlForUpload() {
    let url = new URL(`${super.urlForFindRecord(null, 'receivable-invoices-upload')}`);
    return url.toString();
  }
}
