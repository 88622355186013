import { service } from '@ember/service';

import ApplicationAbility from './application';

export default class AccountingHubAbility extends ApplicationAbility {
  @service userManager;
  @service organizationManager;

  get canAccess() {
    return Boolean(this.organizationManager.accountingOrganizations.length);
  }

  get canAccessOrganization() {
    return this.permissions.accounting_hub.access;
  }

  get canAccessIntegrations() {
    return (
      this.organizationManager.organization.legalCountry === 'FR' &&
      this.organizationManager.accountingOrganizations.length >= 3
    );
  }
}
