import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import ApplicationAbility from './application';

export default class AttachmentAbility extends ApplicationAbility {
  @reads('pricePlanFeatures.probativeValue') canProbate;
  @reads('pricePlanFeatures.attachmentCollection') canAccessEmailForward;

  get canWhitelistEmail() {
    return this.permissions.attachment_collector_whitelist_email.create;
  }

  get canViewEmailForward() {
    return (
      this.permissions.attachment_collector_inbox_email.read && !this.organization.underRegistration
    );
  }

  get canLinkSuggested() {
    return (
      this.permissions.attachment_collector_inbox_email.read &&
      Boolean(this.pricePlanFeatures.attachmentCollection)
    );
  }

  get canReadWhitelistEmail() {
    return this.permissions.attachment_collector_whitelist_email.read;
  }

  get canDeleteWhitelistEmail() {
    return this.permissions.attachment_collector_whitelist_email.delete;
  }

  get canUpdateWhitelistEmail() {
    return this.permissions.attachment_collector_whitelist_email.update;
  }

  get canNavigate() {
    return this.canViewEmailForward;
  }

  get canUse() {
    return this.canAccessEmailForward;
  }

  get canViewImproveXSectionFeature() {
    let {
      organization: { legalCountry },
    } = this.organizationManager;

    return variation('feature--boolean-enable-cross-section-sync') && legalCountry !== 'IT';
  }
}
