import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { einvoicingSettingsNamespace } from 'qonto/constants/hosts';

export default class EinvoicingFrSettingsAdapter extends JSONAPIAdapter {
  namespace = einvoicingSettingsNamespace;

  pathForType() {
    return 'einvoicing/fr/settings';
  }
}
