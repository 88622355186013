import { requestsV4Namespace } from 'qonto/constants/hosts';

import ApplicationAdapter from './application';

export default class RequestMileageAdapter extends ApplicationAdapter {
  namespace = requestsV4Namespace;

  pathForType() {
    return 'requests/supplier_invoices';
  }
}
