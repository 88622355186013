import ApplicationAbility from './application';

export default class AccountsReceivableOnboardingAbility extends ApplicationAbility {
  get canRead() {
    return this.permissions.receivableInvoicesSettings.read;
  }

  get canComplete() {
    return this.permissions.receivableInvoicesSettings.write;
  }
}
