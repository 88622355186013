import { service } from '@ember/service';

import ApplicationAbility from './application';

export default class RibaAbility extends ApplicationAbility {
  @service featuresManager;

  get canRead() {
    let isItalianOrganization = this.organization.legalCountry === 'IT';
    return (
      isItalianOrganization &&
      this.permissions.riba.read &&
      this.featuresManager.isEnabled('passiveRibaVisualization')
    );
  }

  get canUpdate() {
    let isItalianOrganization = this.organization.legalCountry === 'IT';
    return (
      isItalianOrganization &&
      this.permissions.riba.update &&
      this.featuresManager.isEnabled('passiveRibaUpdate')
    );
  }

  get canNavigate() {
    return this.canRead && this.canUpdate;
  }
}
