import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import transformKeys from 'qonto/utils/transform-keys';

import ApplicationAdapter from './application';

export default class ApprovalWorkflowStateAdapter extends ApplicationAdapter {
  @waitFor
  async estimate(requestType, { amount, supplierId, uploaderId }) {
    let response = await adapterAction(this, 'approval-workflow-state', {
      method: 'POST',
      path: 'estimate',
      data: {
        request_type: requestType,
        request_data: {
          amount,
          supplier_id: supplierId,
          uploader_id: uploaderId,
        },
      },
    });

    return transformKeys(response);
  }
}
