import { service } from '@ember/service';

import ApplicationAbility from './application';

export default class QontoPilotAbility extends ApplicationAbility {
  @service organizationManager;

  get canUse() {
    let isOrgUnderRegistration = this.organizationManager.organization?.underRegistration;

    return (
      Boolean(this.pricePlanFeatures.qontoPilot) &&
      this.permissions.bank_accounts.create &&
      this.permissions.external_transfers.create &&
      !isOrgUnderRegistration
    );
  }
}
