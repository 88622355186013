import { variation } from 'ember-launch-darkly';
import { bool } from 'macro-decorators';

import { ORGA_STATUS } from 'qonto/constants/organization';

import ApplicationAbility from './application';

export default class CategoryAbility extends ApplicationAbility {
  @bool('pricePlanFeatures.cfm_category_management') hasCategoryManagementFeature;

  get canAccess() {
    // User is a beta tester
    let isBetaUser = this.featuresManager.isEnabled('displayCashflowV1');

    // User is in soft launch
    let isInSoftLaunch = variation('feature--boolean-cash-flow-first-time-experience');

    // If in soft launch, check if user has the category management feature
    let hasSoftLaunchAccess = isInSoftLaunch && this.hasCategoryManagementFeature;

    let hasFeatureAccess = isBetaUser || hasSoftLaunchAccess;

    return hasFeatureAccess && this.organization.status !== ORGA_STATUS.DEACTIVATED;
  }

  get canViewCashflowCategories() {
    return this.canAccess && variation('feature--boolean-cash-flow-categories-in-mt');
  }

  get canAssign() {
    return this.canAccess && this.permissions.cash_flow_categories.assign;
  }

  get canManage() {
    return this.canAccess && this.permissions.cash_flow_categories.manage;
  }

  get canMemorize() {
    return this.canAccess && this.permissions.cash_flow_categories.manage;
  }
}
