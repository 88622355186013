import { variation } from 'ember-launch-darkly';

import { PAYMENT_LINK_ELIGIBLE_COUNTRIES } from '../constants/payment-links';
import ApplicationAbility from './application';

export default class PaymentLinkAbility extends ApplicationAbility {
  get isEligible() {
    let {
      organization: { legalCountry },
    } = this.organizationManager;

    return (
      PAYMENT_LINK_ELIGIBLE_COUNTRIES.includes(legalCountry) &&
      variation('feature--boolean-mollie-payment-links') &&
      Boolean(this.pricePlanFeatures.paymentLinks)
    );
  }

  get canRead() {
    return this.permissions.paymentLinks.read && this.isEligible;
  }

  get canWrite() {
    return this.permissions.paymentLinks.write && this.isEligible;
  }
}
