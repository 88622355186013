import ApplicationAbility from './application';

export default class AccountsReceivableAdvancedCustomizationAbility extends ApplicationAbility {
  get canView() {
    let { organization } = this.organizationManager;

    return Boolean(this.pricePlanFeatures.bankAccount) && !organization.isItalian;
  }

  get canUse() {
    return Boolean(this.pricePlanFeatures.arAdvancedCustomization);
  }
}
