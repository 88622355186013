import ApplicationAbility from './application';

export default class ExternalAccountAbility extends ApplicationAbility {
  get canImport() {
    return this.iskybAcceptedOrNotRequired && Boolean(this.permissions.external_accounts.import);
  }

  get canOnlyImport() {
    return this.canImport && !this.pricePlanFeatures.bankAccount;
  }

  get canViewConnections() {
    return Boolean(this.permissions.external_accounts.read);
  }

  get canDeleteConnections() {
    return Boolean(this.permissions.external_accounts.delete);
  }
}
