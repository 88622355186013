import { variation } from 'ember-launch-darkly';

import ApplicationAbility from './application';

export default class ApprovalWorkflowAbility extends ApplicationAbility {
  get canSeeState() {
    return Boolean(this.pricePlanFeatures.approvalWorkflows);
  }

  get canRead() {
    return (
      Boolean(this.pricePlanFeatures.approvalWorkflows) && this.permissions.approval_workflows.read
    );
  }

  get canCreate() {
    return Boolean(this.pricePlanFeatures.approvalWorkflows) && this.hasCreatePermission;
  }

  get canUpdate() {
    return (
      Boolean(this.pricePlanFeatures.approvalWorkflows) && this.permissions.approval_workflows.edit
    );
  }

  get canUpsell() {
    return !this.pricePlanFeatures.approvalWorkflows && this.hasCreatePermission;
  }

  get canTryBeforeBuy() {
    return this.canUpsell && variation('feature--boolean-aw-try-before-buy-upsell');
  }

  get hasCreatePermission() {
    return this.permissions.approval_workflows.create;
  }

  get canReadWorkflows() {
    return this.permissions.approval_workflows.read;
  }
}
