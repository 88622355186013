import { service } from '@ember/service';

import HubAdapter from 'qonto/adapters/base/hub';
import { solutionInstanceV2Namespace } from 'qonto/constants/hosts';

export default class SolutionInstanceAdapter extends HubAdapter {
  @service sentry;

  pathForType() {
    return `${this.route}/solution_instances`;
  }

  urlForQuery(query, modelName) {
    let url = this.buildURL(modelName);

    // `type` parameter is supported only in v2
    if (query.type) {
      url = `${this.host}/${solutionInstanceV2Namespace}/solution_instances`;
    }

    return url;
  }

  async deleteRecord(store, _type, snapshot) {
    let gmiSolutionInstance = store
      .peekAll('gmi-solution-instance')
      .find(record => record.solutionInstanceId === snapshot.id);

    try {
      // Delete both records
      await Promise.all([
        // Delete the original solution-instance
        super.deleteRecord(...arguments),
        // Delete the corresponding gmi-solution-instance
        // gmi-solution-instances have no POST request, a record in BE is created by calling POST solution_instances request
        // This allows us to delete them together
        ...(gmiSolutionInstance ? [gmiSolutionInstance.destroyRecord()] : []),
      ]);

      store.unloadRecord(gmiSolutionInstance);
    } catch (error) {
      this.sentry.captureException(error);
      throw error;
    }
  }
}
