import ApplicationAbility from './application';

export default class SubscriptionAbility extends ApplicationAbility {
  get canView() {
    return this.permissions.subscriptions.access && !this.subscriptionManager.isLightPlan;
  }

  get canUpdate() {
    return this.permissions.subscriptions.update;
  }
}
