import ApplicationAbility from './application';

export default class BankAccountAbility extends ApplicationAbility {
  get canAccess() {
    return this.permissions.bank_accounts.access;
  }

  get canNavigate() {
    return this.canAccess;
  }

  get canCreate() {
    let kybAndMultiAccounts = this.isKYBAccepted && this.pricePlanFeatures.multiAccounts;
    return Boolean(this.permissions.bank_accounts.create && kybAndMultiAccounts);
  }

  get canDownloadStatements() {
    return this.permissions.bank_accounts.download_statements;
  }

  get canDownloadCertificate() {
    return this.permissions.bank_accounts.download_account_certification;
  }

  get canCreateCapitalIncrease() {
    return this.permissions.capital_deposits.access;
  }

  get canRead() {
    return this.permissions.bank_accounts.read;
  }

  get canUpdate() {
    return this.permissions.bank_accounts.update;
  }

  get canSeeBalance() {
    return this.permissions.bank_accounts.read_balance;
  }

  get canClose() {
    let secondaryAndActiveOrRemunerated =
      !this.model.main && (this.model.status === 'active' || this.model.isRemunerated);
    return this.permissions.bank_accounts.close && secondaryAndActiveOrRemunerated;
  }

  get canUpsell() {
    return this.isKYBAccepted && this.canAccess && !this.pricePlanFeatures.multiAccounts;
  }

  get canFullAccountDetails() {
    return this.permissions.bank_accounts.full_account_details;
  }
}
