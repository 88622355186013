import ApplicationAbility from './application';

export default class CardAcquirerPaymentAbility extends ApplicationAbility {
  get canRead() {
    return this.featuresManager.isEnabled('tapToPay');
  }

  // to display the entry point in the navigation (we keep atomic abilities)
  get canNavigate() {
    return this.canRead;
  }
}
