import ApiAdapter from 'qonto/adapters/base/api';
import { ribaPaymentNamespace } from 'qonto/constants/hosts';

export default class RibaPaymentAdapter extends ApiAdapter {
  namespace = ribaPaymentNamespace;
  pathForType() {
    return 'riba';
  }

  urlForFindAll() {
    return `${this.namespace}/riba`;
  }
}
